import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Sam Leung </span>
            from <span className="purple"> Hong Kong.</span>
            <br />
            I am currently employed as a software developer at <a className="purple" href="www.mi-expert.com">MiExpert Ltd</a>.
            <br />
            I have completed bachelor's degree in Computer Science from <a className="purple" href="https://hkust.edu.hk/">HKUST</a>.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Fishing
            </li>
            <li className="about-activity">
              <ImPointRight /> Studying latest technologies and trends
            </li>
            <li className="about-activity">
              <ImPointRight /> Searching for new opportunities of Machine Learning
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Try it before you say no to it"{" "}
          </p>
          <footer className="blockquote-footer">Sam Leung</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
