export default function ChangeHead(title,description) {
    document.title = title;
    
    description = description.slice(0, 160);
    document.querySelector('meta[property="og:title"]').setAttribute('content', title);
    document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);

    document.querySelector('meta[name="description"]').setAttribute('content', description);
    document.querySelector('meta[property="og:description"]').setAttribute('content', description);
    document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
    document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
}