import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import MiExpertChatroom from "../../Assets/Projects/MiExpertChatroom.png";
import MiExpertWebsite from "../../Assets/Projects/MiExpertWebsite.png";
import Smartcarpark from "../../Assets/Projects/smartcarpark.png";
import breastcancer from "../../Assets/Projects/breastcancer.png";
import championWing from "../../Assets/Projects/championWing.png";
import translation from "../../Assets/Projects/translation.png";
import recommandation from "../../Assets/Projects/63115930-5f6c1900-bf66-11e9-894f-ecde5ec531b0.png"
import ChangeHead from "../ChangeHead";
function Projects() {
  ChangeHead("Sam Leung Cheuk Wai | Project", "Hi Everyone, I am Sam Leung from Hong Kong. Here are a few projects I've worked on recently. Chatroom APP, MiExpert Website, Edge AI-based Multi-Camera Car Park Occupancy Detection System, Multilingual Blog Recommendation System, Breast Cancer recognition System, Offical Website for a interior design company, Multilingual Translation Plugin for Wordpress.");
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MiExpertChatroom}
              isBlog={false}
              title="MiExpert Chatroom"
              description="A realtime Chatroom APP build with flutter, AWS api gateway, Lambda, Dynamodb. Have features which allows user for realtime messaging, image sharing as well as supports reactions on messages."
              demoLink="https://appdownload.mi-expert.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={MiExpertWebsite}
              isBlog={false}
              title="MiExpert Website"
              description="The website for MiExpert immigration platform is built with React and MUI. We used serverless architecture with AWS S3 and Cloudfront for hosting and AWS Lambda for backend. The website is SEO optimized and has a blog section for the latest news and updates."
              demoLink="https://www.mi-expert.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Smartcarpark}
              isBlog={false}
              title="Edge AI-based Multi-Camera Car Park Occupancy Detection System"
              description="It is an AI-based car park occupancy detection system that uses edge computing to process video streams from multiple cameras. The system is built with Python, OpenCV, and Pytorch. It can detect the number of cars in a car park and provide real-time occupancy information."
              demoLink="https://cse.hkust.edu.hk/ug/fyp/posters/gallery/2023-2024/62_GCH1_Media.mp4"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={recommandation}
              isBlog={false}
              title="Multilingual Blog Recommendation System"
              description="Used the advanced text embedding techniques like 'bge-m3' to build a recommendation system for multilingual blogs. The system recommends similar blogs to the user based on the content of the blog. The system is built with Python"
              demoLink="https://www.mi-expert.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={breastcancer}
              isBlog={false}
              title="Breast Cancer recognition System"
              description="Using yolov8 object detection model to detect breast cancer from x-ray images, the system is built with Python, Pytorch, and OpenCV. The system can detect breast cancer from x-ray images with high accuracy with 80% mAP50:95."
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={championWing}
              isBlog={false}
              title="Offical Website for a interior design company"
              description="The website is built with wordpress and Elementor. It is a responsive website with a clean and modern design. The website showcases the company's portfolio and services."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              demoLink="https://www.championwingltd.com/"   
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={translation}
              isBlog={false}
              title="Multilingual Translation Plugin for Wordpress"
              description="the plugin is built with PHP and Javascript. It is a multilingual translation plugin for wordpress websites. The plugin can translate the website content into multiple languages and supports multiple translation services."
              ghLink="https://github.com/cwleungar/WordpressTranslatePlugin"
              demoLink="https://solution.mi-expert.com"   
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
